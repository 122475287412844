exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-entwicklung-kosten-rechner-tsx": () => import("./../../../src/pages/app-entwicklung-kosten-rechner.tsx" /* webpackChunkName: "component---src-pages-app-entwicklung-kosten-rechner-tsx" */),
  "component---src-pages-apps-tsx": () => import("./../../../src/pages/apps.tsx" /* webpackChunkName: "component---src-pages-apps-tsx" */),
  "component---src-pages-digitalisierungs-beratung-tsx": () => import("./../../../src/pages/digitalisierungs-beratung.tsx" /* webpackChunkName: "component---src-pages-digitalisierungs-beratung-tsx" */),
  "component---src-pages-imprint-tsx": () => import("./../../../src/pages/imprint.tsx" /* webpackChunkName: "component---src-pages-imprint-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-javascript-beratung-tsx": () => import("./../../../src/pages/javascript-beratung.tsx" /* webpackChunkName: "component---src-pages-javascript-beratung-tsx" */),
  "component---src-pages-leistungen-apps-tsx": () => import("./../../../src/pages/leistungen/apps.tsx" /* webpackChunkName: "component---src-pages-leistungen-apps-tsx" */),
  "component---src-pages-leistungen-beratung-digitalisierung-tsx": () => import("./../../../src/pages/leistungen/beratung-digitalisierung.tsx" /* webpackChunkName: "component---src-pages-leistungen-beratung-digitalisierung-tsx" */),
  "component---src-pages-leistungen-javascript-consulting-tsx": () => import("./../../../src/pages/leistungen/javascript-consulting.tsx" /* webpackChunkName: "component---src-pages-leistungen-javascript-consulting-tsx" */),
  "component---src-pages-leistungen-ki-integration-tsx": () => import("./../../../src/pages/leistungen/ki-integration.tsx" /* webpackChunkName: "component---src-pages-leistungen-ki-integration-tsx" */),
  "component---src-pages-leistungen-low-code-tsx": () => import("./../../../src/pages/leistungen/low-code.tsx" /* webpackChunkName: "component---src-pages-leistungen-low-code-tsx" */),
  "component---src-pages-leistungen-webapps-tsx": () => import("./../../../src/pages/leistungen/webapps.tsx" /* webpackChunkName: "component---src-pages-leistungen-webapps-tsx" */),
  "component---src-pages-leistungen-webdesign-tsx": () => import("./../../../src/pages/leistungen/webdesign.tsx" /* webpackChunkName: "component---src-pages-leistungen-webdesign-tsx" */),
  "component---src-pages-leistungen-website-relaunch-tsx": () => import("./../../../src/pages/leistungen/website-relaunch.tsx" /* webpackChunkName: "component---src-pages-leistungen-website-relaunch-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-references-antodo-tsx": () => import("./../../../src/pages/references/antodo.tsx" /* webpackChunkName: "component---src-pages-references-antodo-tsx" */),
  "component---src-pages-references-booking-tsx": () => import("./../../../src/pages/references/booking.tsx" /* webpackChunkName: "component---src-pages-references-booking-tsx" */),
  "component---src-pages-references-delivery-tsx": () => import("./../../../src/pages/references/delivery.tsx" /* webpackChunkName: "component---src-pages-references-delivery-tsx" */),
  "component---src-pages-references-dezim-tsx": () => import("./../../../src/pages/references/dezim.tsx" /* webpackChunkName: "component---src-pages-references-dezim-tsx" */),
  "component---src-pages-references-digi-tsx": () => import("./../../../src/pages/references/digi.tsx" /* webpackChunkName: "component---src-pages-references-digi-tsx" */),
  "component---src-pages-references-mae-tsx": () => import("./../../../src/pages/references/mae.tsx" /* webpackChunkName: "component---src-pages-references-mae-tsx" */),
  "component---src-pages-references-market-tsx": () => import("./../../../src/pages/references/market.tsx" /* webpackChunkName: "component---src-pages-references-market-tsx" */),
  "component---src-pages-references-teo-tsx": () => import("./../../../src/pages/references/teo.tsx" /* webpackChunkName: "component---src-pages-references-teo-tsx" */),
  "component---src-pages-references-tsx": () => import("./../../../src/pages/references.tsx" /* webpackChunkName: "component---src-pages-references-tsx" */)
}

